
import * as React from "react";
import imgSoftwarrServices from '../images/services_offered-software.png'
import imgCloudServices from '../images/services_offered-cloud.png'
import imgProductServices from '../images/services_offered-product.png'
import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "../utils/motion";
import { TitleText, TypingText } from "./CustomText";
function Services() {
    return (

        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            className=" hero bg-white dark:bg-gray-900 min-h-screen align-middle"
            id='FLServices'
        >
            <div className="container px-6 py-10 mx-auto" >
                <div className="text-center">
                    <TypingText className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white" title={"Solutions we offer"} />
                    {/* <h1 className="">Solutions we offer</h1> */}
                    <TitleText
                        textStyles="max-w-6xl mx-auto mt-4 text-gray-500 text-2xl"
                        title={"We focus on growing our business with small and large organizations around the globe by providing domain-specific, intelligent and cost-effective Software solutions through Agile methodology."}
                    />
                </div>

                <div className="grid grid-cols-1 gap-8 mt-8 lg:grid-cols-3 xl:grid-cols-3 h-full">

                    <motion.div
                        variants={fadeIn('right', 'spring', 1 * 0.1, 0.75)}

                    >
                        <img className="relative z-10 object-cover w-full rounded-md h-96 border-gray-200 border-2" src={imgSoftwarrServices} alt=""></img>

                        <div className="relative z-20 max-w-lg p-6 mx-auto -mt-20 rounded-md border-2 border-blue-400 shadow-lg dark:bg-gray-900 bg-gray-50">
                            <div className="font-bold text-2xl mb-8">Software Solutions</div>
                            <div className="grid grid-cols-1 divide-y-2 text-gray-500  text-lg ">
                                <div className='pb-3'>Custom web and mobile application development</div>
                                <div className='pb-3 pt-3'>Custom enterprise software solutions</div>
                                <div className='pb-3 pt-3'>Application and Technology modernization and migrations</div>
                                <div className='pb-3 pt-3'>Database as well as Big Data design and management and data visualization</div>
                                <div className='pb-3 pt-3'>API development and integrations</div>
                                <div className='pb-3 pt-3'>IoT integrations</div>
                                <div className='pb-3 pt-3'>ML/AI service integrations</div>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        variants={fadeIn('right', 'spring', 2 * 0.2, 0.75)}
                    >
                        <img className="relative z-10 object-cover w-full rounded-md h-96 border-gray-200 border-2" src={imgCloudServices} alt=""></img>

                        <div className="relative z-20 max-w-lg p-6 mx-auto -mt-20 rounded-md border-2 border-blue-400 shadow-lg dark:bg-gray-900 bg-gray-50">
                            <div className="font-bold text-2xl mb-4">Cloud-Infra Solutions</div>
                            <div className="grid grid-cols-1 divide-y-2 text-gray-500  text-lg">
                                <div className='pb-3'>Cloud infrastructure architecture design, development and deployments</div>
                                <div className='pb-3 pt-3'>Cloud infrastructure maintenance</div>
                                <div className='pb-3 pt-3'>Cloud infrastructure Terraforming</div>
                                <div className='pb-3 pt-3'>Cloud infrastructure migrations</div>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        variants={fadeIn('right', 'spring', 3 * 0.3, 0.75)}
                    >
                        <img className="relative z-10 object-cover w-full rounded-md h-96 border-gray-200 border-2" src={imgProductServices} alt=""></img>

                        <div className="relative z-20 max-w-lg p-6 mx-auto -mt-20 rounded-md border-2 border-blue-400 shadow-lg dark:bg-gray-900 bg-gray-50">
                            <div className="font-bold text-2xl mb-4">Software Product Solutions</div>
                            <div className="grid grid-cols-1 divide-y-2 text-gray-500 text-lg">
                                <div className='pb-3'>Idea to market</div>
                                <div className='pb-3 pt-3'>Product design and development</div>
                                <div className='pb-3 pt-3'>Product management</div>
                                <div className='pb-3 pt-3'>Product modernization</div>
                            </div>
                        </div>
                    </motion.div>
                </div>

            </div >
        </motion.div >
    );
}

export default Services;