import * as React from "react";
import imgHeroImage from '../images/site_title_image.png'
import { motion } from "framer-motion";
import { navVariants, staggerContainer, fadeIn } from "../utils/motion";
import { TitleText, TypingText } from "./CustomText";

function Hero() {

    return (
        <div

        >
            <div className="hero px-6 py-16 mx-auto min-h-screen align-middle"
            >
                <div className="gradient-03" />
                <div className="items-center lg:flex">
                    <div className="w-full lg:w-1/3">
                        <motion.div
                            variants={staggerContainer}
                            initial="hidden"
                            whileInView="show"
                            className="lg:max-w-lg text-center lg:text-left"
                        >
                            <TypingText title={"Smarter Software Solutions"} textStyles="z-10 text-xl font-semibold text-gray-800 uppercase dark:text-white lg:text-xl text-center lg:text-left" />
                            <TitleText textStyles={"mt-2 text-gray-600 dark:text-gray-400 text-xl lg:text-2xl  text-center lg:text-left"} title={"We design and develop smarter software solutions that put our clients and their organizational evolution at the forefront, helping their business to thrive in the Digital Age."} />
                            {/* <p className="mt-2 text-gray-600 dark:text-gray-400 text-xl lg:text-2xl  text-center lg:text-left">We design and develop smarter software solutions
                                that put our clients and their organizational evolution at the forefront, helping their business to thrive in the Digital Age.</p> */}

                            <button className="tracking-wider px-6 py-2.5 mt-6 text-sm text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500 justify-center">Shop Now</button>
                        </motion.div>
                    </div>

                    <motion.div
                        variants={fadeIn('right', 'spring', 1 * 0.5, 0.75)}
                        initial="hidden"
                        whileInView="show"
                        className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-2/3"
                    >
                        <img src={imgHeroImage} alt='' className="" />
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
