import * as React from "react";
import Services from '../components/Services'
import Hero from '../components/Hero'
import Approaches from '../components/Approaches'
import Footer from '../components/Footer.jsx'
import Engagements from '../components/Engagements'
import Navbar from '../components/Navbar'
const IndexPage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Services />
      <Approaches />
      <Engagements />
      <Footer />
    </div>
  )
}

export default IndexPage

export const Head = () => <title>FourLink</title>
