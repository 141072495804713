import * as React from "react";
import icoCommitment from '../icons/handshake.png'
import icoExcellence from '../icons/experience.png'
import icoAdapt from '../icons/adaptation.png'
import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "../utils/motion";
import { TypingText } from "./CustomText";


function Approaches() {
    return (

        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            className=" hero bg-white dark:bg-gray-900 min-h-screen align-middle"
            id='FLApproaches'>
            <div className="container px-6 py-10 mx-auto">
                <motion.h1
                    variants={fadeIn('right', 'spring', 1 * 0.5, 0.7)}
                    className="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">
                    Our Approach to <motion.span
                        variants={fadeIn('right', 'spring', 2 * 0.5, 0.7)}
                        className="text-blue-500">
                        Success
                    </motion.span>
                </motion.h1>

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-1 xl:grid-cols-3">
                    <motion.div
                        variants={fadeIn('right', 'spring', 1 * 0.5, 0.7)}
                        className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl border-2 border-blue-400 dark:bg-gray-800">
                        <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                            <img src={icoCommitment} className='h-10' alt=''></img>
                        </span>

                        <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">Affective Commitment</h1>

                        <p className="text-gray-500 dark:text-gray-300 text-lg">
                            With our “Clients First” approach, we strive to be of value to our clients and not just a success. We are invested in creating client relationships through our software product and services that grow stronger and last longer.
                        </p>

                        <a href="#FLApproaches" className="flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                            <span className="mx-1">read more</span>
                            <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                    </motion.div>

                    <motion.div
                        variants={fadeIn('right', 'spring', 2 * 0.5, 0.7)}
                        className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl dark:bg-gray-800  border-2 border-blue-400">
                        <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                            <img src={icoExcellence} className='h-10' alt=''></img>
                        </span>

                        <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">Deliver the Excellence</h1>

                        <p className="text-gray-500 dark:text-gray-300  text-lg">
                            Our way of delivering excellence is <b>“HAS”</b> -
                            <p><b>High performance</b> - set aggressive goals and stay focused to achieve them</p>
                            <p><b>Accountable</b> - Take ownership of whatever you work on and deliver, and be responsible for the results</p>
                            <p><b >Strategic</b> - make clear choices, anticipate the ever changing world, and always plan for the future.</p>

                        </p>

                        <a href="#FLApproaches" className="flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                            <span className="mx-1">read more</span>
                            <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                    </motion.div>

                    <motion.div
                        variants={fadeIn('right', 'spring', 3 * 0.5, 0.7)}
                        className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl dark:bg-gray-800  border-2 border-blue-400">
                        <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                            <img src={icoAdapt} className='h-10' alt=''></img>
                        </span>

                        <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">Adapt and Evolve</h1>

                        <p className="text-gray-500 dark:text-gray-300  text-lg">
                            In this ever-changing world, we firmly believe that enjoying success requires the ability to adapt. Instead of approaching “change” as one of our projects, we have embedded change-readiness into our culture, decisions, expectations and capabilities. We adapt and evolve quickly and continuously
                        </p>

                        <a href="#FLApproaches" className="flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                            <span className="mx-1">read more</span>
                            <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                    </motion.div>
                </div>
            </div>
        </motion.div>

    );
}

export default Approaches;