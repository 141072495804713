import * as React from "react";
function Footer() {
    return (

        <footer className="bg-white dark:bg-gray-900">
            <div className="container px-6 py-8 mx-auto">

                <hr className="my-10 border-gray-200 dark:border-gray-700" />
                <div className="text-center">
                    <p className="max-w-lg mx-auto mt-2 text-gray-500 dark:text-gray-400 text-2xl">We envision ourselves as the Technology Partner of choice for your organization to offer high impact, long-term business benefits through Information Technology.</p>

                    <div className="flex flex-col mt-4 sm:flex-row sm:items-center sm:justify-center">


                        <button className="w-full px-5 py-2 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">Get started</button>
                    </div>
                </div>

                <hr className="my-10 border-gray-200 dark:border-gray-700" />

                <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                    <p className="text-sm text-gray-400">© Copyright {new Date().getFullYear()}. All Rights Reserved.</p>

                    <div className="flex mt-3 -mx-2 sm:mt-0">
                        <a href="#" className="mx-2 text-sm text-gray-400 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit"> Teams </a>

                        <a href="#" className="mx-2 text-sm text-gray-400 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit"> Privacy </a>

                        <a href="#" className="mx-2 text-sm text-gray-400 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit"> Cookies </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;