import { motion } from "framer-motion";
import * as React from "react";
import { fadeIn, slideIn, staggerContainer } from "../utils/motion";

function Engagements() {
    return (

        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            className=" hero bg-white dark:bg-gray-900 min-h-screen" id='FLEngagements'>
            <div className="container px-6 py-8 mx-auto">
                <div className="text-center pb-10">
                    <motion.h1
                        variants={fadeIn('right', 'spring', 1 * 0.5, 0.7)}
                        className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">
                        Our engagement models
                    </motion.h1>

                    <motion.p
                        variants={fadeIn('left', 'spring', 1 * 0.5, 0.7)}
                        className="max-w-4xl mx-auto mt-4 text-gray-500 text-2xl">
                        Before taking up a project, we take enough time to understand its business needs, product specifications, manpower requirements, volatility and complexities.
                    </motion.p>
                </div>
                <div className="flex flex-col items-center justify-center space-y-8 lg:-mx-4 lg:flex-row lg:items-stretch lg:space-y-0">
                    <motion.div
                        variants={fadeIn('right', 'spring', 1 * 0.5, 0.7)}
                        className="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-blue-400 rounded-lg lg:mx-4 dark:bg-gray-900 dark:border-gray-700">
                        <div className="flex-shrink-0">
                            <h2 className="inline-flex items-center justify-center px-2 text-2xl font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700">
                                Fixed Price
                            </h2>
                        </div>

                        <ul className="flex-1 space-y-4 divide-y-2 text-lg">
                            <li className="text-gray-500 dark:text-gray-400">
                                Suitable for small to medium size projects
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Pre-defined project requirements
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Less flexibility
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Predefined budget
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Client has less control over change requests
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Waterfall development model
                            </li>
                        </ul>
                    </motion.div>

                    <motion.div
                        variants={fadeIn('right', 'spring', 2 * 0.5, 0.7)}
                        className="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-blue-400 rounded-lg lg:mx-4 dark:bg-gray-900 dark:border-gray-700">
                        <div className="flex-shrink-0">
                            <h2 className="inline-flex items-center justify-center px-2 text-2xl  font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700">
                                Time and Material
                            </h2>
                        </div>

                        <ul className="flex-1 space-y-4 divide-y-2 text-lg">
                            <li className="text-gray-500 dark:text-gray-400">
                                Suitable for small to medium size projects
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                No pre-defined requirements
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                High flexibility
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                No pre-defined budget
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                No pre-defined budget
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Client has significant control over change requests
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Agile development model
                            </li>
                        </ul>

                    </motion.div>

                    <motion.div
                        variants={fadeIn('right', 'spring', 3 * 0.5, 0.7)}
                        className="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-blue-400 rounded-lg lg:mx-4 dark:bg-gray-900 dark:border-gray-700">
                        <div className="flex-shrink-0">
                            <h2 className="inline-flex items-center justify-center px-2 text-2xl font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700">
                                Dedicated Team
                            </h2>
                        </div>

                        <ul className="flex-1 space-y-4 divide-y-2 text-lg">
                            <li className="text-gray-500 dark:text-gray-400">
                                Suitable for large size projects
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Evolving project requirements
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                High flexibility
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Estimated budgets
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Client has full or partial control over change requests
                            </li>

                            <li className="text-gray-500 dark:text-gray-400 pt-4">
                                Agile development model
                            </li>
                        </ul>
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
}

export default Engagements;